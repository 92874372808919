import React, { useState, useRef } from "react";
import IconBank from "../../assets/landing/img/shop.svg";
import priceBar from "../../assets/landing/img/price_bar.svg";
import setup from "../../assets/landing/img/setup.svg";
import ui from "../../assets/landing/img/ui.svg";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import axios from "axios";
import tanslate from "../../views/translate/languages";
import NotificationAlert from "react-notification-alert";

const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;

const Form = ({ lang = "eng" }) => {
  const [formData, setFormData] = useState({
    contact: "",
    name: "",
    studyasto: "",
    proastro: "",
    city: "",
  });
  const [errors, setErrors] = useState({});
  const [overlay, setOverlay] = useState(true);
  const [loader, setLoader] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isSucess, setIsSuccess] = useState(false);
  const notificationAlertRef = React.useRef(null);
  function handleChange() {
    setOpen(!isOpen);
  }

  console.log(formData, "formData");
  const Grades = {
    eng: [
      {
        heading:
          "Designed with precision and boundless opportunities in the realm of astroscience, our application can resolve your (including your client’s) queries using real time data and predictive analytics with high accuracy. With that said, the following are the features that demonstrate why ours is the best astrology software.",
        sub_heading: "Best Astrology Software with Powerful Features",
        points: [
          {
            num: "1",
            img: IconBank,
            title: "Popular Types of Chart",
            sub_title:
              "With Vashist, Lagan, Gochar, and Varshphal—the popular types of charts—you can get precise horoscope analysis based on the laws of planetary positions (current or actual), birth details, and yearly trends.",
          },
          {
            num: "2",
            img: priceBar,
            title: "Kundali Details at your Fingertips",
            sub_title:
              "Access comprehensive Kundali details effortlessly with just a few clicks. Our platform brings all the essential information right to your fingertips.",
          },
          {
            num: "3",
            img: setup,
            title: "Favourable and Unfavourable Planets",
            sub_title:
              "Using the software, you can analyze planetary behaviour and its impact on your life. With accuracy, you can determine whether each planet affects you positively or negatively",
          },
          {
            num: "4",
            img: ui,
            title: "Dasha Calculation",
            sub_title:
              "We all are most concerned with the past, present, and future. Our Kundali software's predictive analytics model offers accurate calculation of Vimshottari Dasha, LK Dasha and Varshfal with predictions and remedies.",
          },
        ],
      },
    ],

    hin: [
      {
        heading:
          "ज्योतिष विज्ञान के क्षेत्र में असीमित अवसरों और सटीकता के साथ डिज़ाइन किया गया, हमारा एप्लिकेशन वास्तविक समय डेटा और भविष्यवाणी विश्लेषण का उपयोग करके आपकी समस्याओं को उच्च सटीकता के साथ हल कर सकता है। इसके साथ ही, निम्नलिखित विशेषताएं हैं जो यह दर्शाती हैं कि हमारा सॉफ्टवेयर क्यों सर्वश्रेष्ठ कुंडली सॉफ्टवेयर है:",
        sub_heading:
          "जीडी वशिष्ठ का सॉफ़्टवेयर आपकी उन्नति के लिए सर्वश्रेष्ठ क्यों है?",
        points: [
          {
            num: "1",
            img: IconBank,
            title: "चार प्रमुख प्रकार के चार्ट",
            sub_title:
              "वशिष्ट, लग्न, गोचर, और वर्षफल—चार प्रमुख चार्टों के साथ—आप ग्रहों की स्थिति (वर्तमान या वास्तविक), जन्म के विवरण, और वार्षिक रुझानों के आधार पर सटीक कुंडली विश्लेषण प्राप्त कर सकते हैं।",
          },
          {
            num: "2",
            img: priceBar,
            title: "कुंडली का विस्तृत ज्ञान अब उँगलियों पर",
            sub_title:
              "किसी अन्य कुंडली सॉफ्टवेयरके विपरीत, हमारा एप्लीकेशन बहुत आसान और उपयोगकर्ता-अनुकूल है | बस जीडी वशिष्ट कुंडली सॉफ्टवेयर का मुफ्त डाउनलोड करें, और आप अपने ज्योतिष ज्ञान का नया सफ़र शुरू कर सकते हैं!",
          },
          {
            num: "3",
            img: setup,
            title: "ग्रहों का व्यवहार मूल्यांकन",
            sub_title:
              "जी डी वशिष्ठ कुंडली सोफ्टवेयर का उपयोग करके, आप उन ग्रहों के व्यवहार का निर्धारण कर सकते हैं जो आपके जीवन की दिशा को प्रभावित करते हैं। सटीकता के साथ, आप जान सकते हैं कि प्रत्येक ग्रह का व्यवहार आपको सकारात्मक या नकारात्मक रूप से कैसे प्रभावित करता है।",
          },
          {
            num: "4",
            img: ui,
            title: "दशा ग्रह भविष्यवाणी",
            sub_title:
              "अतीत, वर्तमान, और भविष्य, वे हैं जिनके बारे में हम सभी सबसे अधिक चिंतित और जानने के इच्छुक होते हैं। हमारे कुंडली सॉफ्टवेयर में भविष्यवाणी विश्लेषिकी मॉडल सटीकता के साथ पूर्वानुमान विवरण प्रदान करता है, जिससे आपको पता चलता है कि कैसा भविष्य आपका इंतजार कर रहा है।",
          },
        ],
      },
    ],
  };

  function handleChange() {
    setOpen(!isOpen);
  }

  const playerRef = useRef(null);

  const validate = () => {
    const errors = {};

    if (!formData.name) {
      errors.name = "Name is required";
    } else if (formData.name.length < 3) {
      errors.name = "Name must be at least 3 characters";
    }

    if (!formData.contact) {
      errors.contact = "Enter Valid Mobile Number";
    } else if (!/^\d{9,15}$/.test(formData.contact)) {
      errors.contact = "Phone number must be between 9 and 15 digits";
    }

    if (!formData.studyasto) {
      errors.studyasto = "Select option";
      // } else if (!/^\d{9,15}$/.test(formData.studyasto)) {
      //   errors.studyasto = "Select option";
    }

    if (!formData.proastro) {
      errors.proastro = "Select option";
      // } else if (!/^\d{9,15}$/.test(formData.proastro)) {
      //   errors.proastro = "Select option";
    }

    if (!formData.city) {
      errors.city = "City is required";
      // } else if (!/^\d{9,15}$/.test(formData.city)) {
      //   errors.city = "Phone number must be between 9 and 15 digits";
    }

    return errors;
  };

  const onChange = (e) => {
    delete errors[e.target.name];
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeWithoutSpecialAndNumber = (e) => {
    delete errors[e.target.name];
    let newValue = e.target.value;
    const filteredValue = newValue.replace(/[^A-Za-z\s]/g, " ");
    setErrors(errors);
    setFormData({ ...formData, [e.target.name]: filteredValue });
  };

  const HandleSubmit = async (e) => {
    setIsSuccess(false);
    console.log(errors, "errors");
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    let data = {
      name: formData.name,
      contact: formData.contact,
      studyasto: formData.studyasto,
      proastro: formData.proastro,
      city: formData.city,
    };

    if (Object.keys(validationErrors).length === 0) {
      try {
        setLoader(true);
        const res = await axios.post(`${BAS_URL_APPAPI}acharya/visitor`, data);

        if (res.data.success === 1) {
          setOverlay(false);
          setLoader(false);
        }
        // console.log(res, "hhujhjhj");
        //  setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormData({
          contact: "",
          name: "",
          studyasto: "",
          proastro: "",
          city: "",
        });
        setIsSuccess(true);
        // let options = {};
        // options = {
        //   place: "tc",
        //   message: "",
        //   type: "primary",
        //   icon: "tim-icons icon-bell-55",
        //   autoDismiss: 7,
        // };
        // notificationAlertRef.current.notificationAlert(options);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const heros = {
    eng: [
      {
        img: "",
        title:
          "Discover the Secrets of Stars with India’s Leading Astrology Software",
        sub_title:
          "With our advanced astrology software—highly productive yet user-friendly for acharyas, beginners, or enthusiasts—you can take your astrological practice to new heights with Gurudev GD Vashist's proven principles and insights!",
      },
    ],
    hin: [
      {
        img: "",
        title:
          "भारत के सर्वोत्तम ज्योतिष सॉफ्टवेयर के साथ करे ज्योतिषीय रहस्यों की खोज",
        sub_title:
          "हमारे कुंडली सॉफ्टवेयर के साथ, अनुभवी आचार्यों/ज्योतिषी या ज्योतिष विद्या सिखने में रूचि रखने वाले उत्साही लोगों के लिए अत्यधिक उपयोगी और अनुकूल है - आप गुरुदेव जीडी वशिष्ठ के असीमित ज्ञान, सिद्धांतों और अंतर्दृष्टि  के साथ अपने ज्योतिषीय अभ्यास को एक नयी ऊँचाइयों पर ले जा सकते है!",
      },
    ],
  };

  console.log(formData,"formData")

  return (
    <>
      {Grades[lang].map((item_main, index_main) => (
        <>
          <div
            key={index_main}
            className="col-xl-12 col-md-12 wow px-0"
            data-aos="fade-in"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            {/* <div className="cs_height_60 cs_height_lg_40"></div> */}
            <div className="row">
              <div
                className="col-xl-12 col-md-12 wow flex justify-center"
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <form
                  id="cs_form"
                  className="row h-full w-full  bg-gradient-to-r from-violet-200 to-pink-200 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-0 border border-gray-100 py-4 justify-center wow fadeInRight  animate__animated px-0"
                  onSubmit={HandleSubmit}
                >
                  <h1 className="text-[30px] text-center w-50 text-dark">
                    {tanslate.contactUs[lang]}
                  </h1>

                  <div className="col-sm-12 mb-3">
                    {/* <label className="text-dark" htmlFor="name">
                      {tanslate.name[lang]}
                    </label> */}
                    <input
                      id="name"
                      type="text"
                      name="name"
                      className="cs_form_field py-2"
                      placeholder={tanslate.fullName[lang]}
                      value={formData.name}
                      onChange={(e) => onChangeWithoutSpecialAndNumber(e)}
                    />
                    {errors.name && (
                      <small>
                        <div className="text-danger text-start">
                          {errors.name}
                        </div>
                      </small>
                    )}
                    {/* <div className="cs_height_30 cs_height_lg_30"></div> */}
                  </div>
                  <div className="col-sm-12 video-field mb-3">
                    {/* <label className="text-dark" htmlFor="Contact">
                      {tanslate.contact[lang]}
                    </label> */}
                    <input
                      id="Contact"
                      type="number"
                      name="contact"
                      className="cs_form_field py-2"
                      placeholder={tanslate.mobile[lang] + "*"}
                      min="10"
                      value={formData.contact}
                      onChange={(e) => onChange(e)}
                    />
                    {errors.contact && (
                      <small>
                        <div className="text-danger text-start">
                          {errors.contact}
                        </div>
                      </small>
                    )}
                    {/* <div className="cs_height_30 cs_height_lg_30"></div> */}
                  </div>
                  <div className="col-sm-12 video-field mb-3">
                    {/* <label className="text-dark" htmlFor="City">
                      {tanslate.city[lang]}
                    </label> */}
                    <input
                      id="City"
                      type="text"
                      name="city"
                      className="cs_form_field py-2"
                      placeholder={tanslate.city[lang] + "*"}
                      min="10"
                      value={formData.city}
                      onChange={(e) => onChange(e)}
                    />
                    {errors.city && (
                      <small>
                        <div className="text-danger text-start">
                          {errors.city}
                        </div>
                      </small>
                    )}
                  </div>
                  <div className="col-sm-12 video-field mb-1 mt-2">
                    <label className="text-dark" htmlFor="City">
                      {tanslate.haveStudiedAstrology[lang]}
                    </label>

                    <div className="flex">
                      <div className="flex items-center mr-2">
                        <input
                          id="default-radio-1"
                          type="radio"
                          value="yes"
                          name="studyasto"
                          onChange={(e) => onChange(e)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600"
                        />
                        <div>
                          <label
                            for="default-radio-1"
                            className="ms-2 text-sm  font-medium text-dark dark:text-gray-300 mb-0"
                          >
                            {tanslate.yes[lang]}
                          </label>
                        </div>
                      </div>

                      <div className="flex items-center">
                        <input
                          id="default-radio-1"
                          type="radio"
                          defaultValue="no"
                          value="no"
                          name="studyasto"
                          onChange={(e) => onChange(e)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600"
                        />
                        <label
                          for="default-radio-1"
                          className="ms-2 text-sm font-medium text-dark dark:text-gray-300 mb-0"
                        >
                          {tanslate.no[lang]}
                        </label>
                      </div>
                    </div>

                    {errors.studyasto && (
                      <small>
                        <div className="text-danger text-start">
                          {errors.studyasto}
                        </div>
                      </small>
                    )}
                    {/* <div className="cs_height_30 cs_height_lg_30"></div> */}
                  </div>

                  <div className="col-sm-12 video-field mb-1">
                    <label className="text-dark" htmlFor="City">
                      {tanslate.isProfessionalAstrologer[lang]}
                    </label>
                    <div className="flex">
                      <div className="flex items-center mr-2">
                        <input
                          id="default-radio-2"
                          type="radio"
                          defaultValue="yes"
                          value="yes"
                          name="proastro"
                          onChange={(e) => onChange(e)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600"
                        />
                        <div>
                          <label
                            for="default-radio-2"
                            className="ms-2 text-sm  font-medium text-dark dark:text-gray-300 mb-0"
                          >
                            {tanslate.yes[lang]}
                          </label>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="default-radio-2"
                          type="radio"
                          value="no"
                          name="proastro"
                          onChange={(e) => onChange(e)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600"
                        />
                        <label
                          for="default-radio-2"
                          className="ms-2 text-sm font-medium text-dark dark:text-gray-300 mb-0"
                        >
                          {tanslate.no[lang]}
                        </label>
                      </div>
                    </div>
                    {errors.proastro && (
                      <small>
                        <div className="text-danger text-start">
                          {errors.proastro}
                        </div>
                      </small>
                    )}
                    {/* <div className="cs_height_30 cs_height_lg_30"></div> */}
                  </div>

                  <div className="col-lg-12 justify-center flex my-2">
                    <button
                      className="cs_btn cs_bg_accent cs_modal_btn max-lg:block"
                      type="submit"
                    >
                      {tanslate.submit[lang]}
                    </button>
                  </div>

                  {isSucess && (
                    <small className="text-green-800">
                      Thank you for submitting your details
                    </small>
                  )}
                </form>
              </div>
            </div>{" "}
          </div>
        </>
      ))}
      <NotificationAlert ref={notificationAlertRef}></NotificationAlert>
    </>
  );
};

export default Form;
